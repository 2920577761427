<template>
	<div class="home">
		<table-approvingofficer />
	</div>
</template>

<script>
import tableApprovingOfficer from '@/components/pages/maint/approvingofficer/tableApprovingOfficer.vue';

export default {
	components: {
		'table-approvingofficer': tableApprovingOfficer,
	}
};
</script>

<style>

</style>
